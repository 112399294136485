<template>
  <div class="container wapper">
    <div class="page-title">
      <h1 class="title">{{ titleText }}</h1>
      <p class="title-button" @click="switchLoginType">{{ titleButtonText }}</p>
    </div>
    <van-cell-group class="form" :border="false">
      <van-field class="form-item" placeholder="请输入手机号" type="number" center v-model="phoneValue" :border="false"
        :clearable="true" :maxlength="11" />

      <van-field class="form-item" placeholder="请输入验证码" type="number" center v-model="codeValue" :border="false"
        :clearable="true" :maxlength="4" v-if="loginType === 1">
        <template #right-icon>
          <div class="text " :class="isDisableCode ? 'text-disable' : ''" @click="getCode()">
            {{ codeText }}
          </div>
        </template>
      </van-field>

      <van-field class="form-item" placeholder="请输入密码" center type="password" v-model="passValue" :border="false"
        :clearable="true" :maxlength="20" v-if="loginType === 2">
        <template #right-icon>
          <div class="text " @click="toLoginReset()">忘记密码</div>
        </template>
      </van-field>
    </van-cell-group>

    <van-button round type="primary" class="button" @click="submit" :disabled="!buttonDisabled">登录
    </van-button>
    <p class="tips-text">新手机号将自动开始注册</p>
  </div>
</template>

<script>
import mixinLogin from '@/mixins/login'
import commonMixins from '@/mixins/common'

export default {
  data () {
    return {
      // 登录类型 1验证码登录 2 密码登录
      loginType: 1,
      isSubmitResponse: true, //提交防抖
    }
  },
  mixins: [mixinLogin, commonMixins],
  computed: {
    // 页面标题文本
    titleText () {
      return this.loginType === 1 ? '手机快捷登录 / 注册' : '密码登录'
    },
    // 页面标题按钮
    titleButtonText () {
      return this.loginType === 1 ? '密码登录' : '快捷登录'
    },
    // 按钮禁用
    buttonDisabled () {
      let loginType = this.loginType,
        phoneValue = this.phoneValue,
        passValue = this.passValue,
        codeValue = this.codeValue

      if (loginType === 2) {
        return phoneValue.length >= 11 && passValue.length >= 6
      } else {
        return phoneValue.length >= 11 && codeValue.length >= 4
      }
    },
  },
  methods: {
    // 切换登录形式
    switchLoginType () {
      if (this.loginType === 1) {
        this.loginType = 2
      } else {
        this.loginType = 1
      }
    },

    submit () {
      if (!this.isSubmitResponse) {
        return false
      }
      this.isSubmitResponse = false

      let verifyPhoneResult = this.verifyPhoneValue(),
        verifyPasswordResult = this.verifyPasswordValue(),
        verifyCodeValueResult = this.verifyCodeValue()
      let param = {}

      if (!verifyPhoneResult.result) {
        this.$toast(verifyPhoneResult.msg)
        return
      }

      if (this.loginType === 1) {
        if (!verifyCodeValueResult.result) {
          this.$toast(verifyCodeValueResult.msg)
          return
        }
        param.type = 'code'
        param.code = verifyCodeValueResult.value
      } else {
        if (!verifyPasswordResult.result) {
          this.$toast(verifyPasswordResult.msg)
          return
        }
        param.type = 'password'
        param.password = verifyPasswordResult.value
      }

      param.phone = verifyPhoneResult.value

      this.$store.dispatch('Login', param).then((res) => {
        this.isSubmitResponse = true
        let status = res?.status

        // 未注册
        if (status === 2) {
          // 用户需要注册
          let query = {}
          query.phone = param.phone
          query.regcode = res.data.regcode
          this.$router.push({ name: 'loginRegister', query })
          return
        }

        // 请求失败
        if (status !== 1) {
          this.$toast(res.message)
          return
        }

        if (window.history.length === 1) {
          this.$router.replace({ path: '/' })
          return
        }

        window.history.back()
      })
    },
    // 进入重置密码界面
    toLoginReset () {
      this.$router.push({ path: '/login/reset' })
    },
  },
  mounted () {
    this.$util.setShare()
  },
}
</script>

<style lang="scss" scoped>
.page-title {
  @include flex_between_center;
  margin-bottom: 0.5rem;
  padding-top: 1rem;

  .title {
    font-size: 0.64rem;
    line-height: 0.88rem;
    font-weight: 600;

    &-button {
      font-size: 0.35rem;
      line-height: 0.88rem;

      color: var(--main-color);
    }
  }
}

.wapper {
  background-color: #fff;
}

.button {
  width: 100%;
  font-size: 0.43rem;
}

.form {
  margin-bottom: 0.75rem;

  &-item {
    border-bottom: 0.01rem solid #ebecf0;
    height: 1.76rem;

    /deep/ .van-field__control {
      font-size: 0.43rem;
    }

    .text {
      font-size: 0.37rem;
      line-height: 0.37rem;
      color: #18191a;
      width: 2.4rem;
      border-left: 0.01rem solid #ebecf0;
      text-align: right;
      box-sizing: border-box;

      &-disable {
        color: #c2c5cc;
      }
    }
  }
}

.tips-text {
  font-size: 0.37rem;
  color: #c2c5cc;
  text-align: center;
  margin-top: 0.75rem;
}
</style>
